<template>
  <div>
    <v-app-bar
      id="k-d-a-navigation-appbar"
      ref="containerRef"
      app
      elevation="1"
      height="80"
      :color="bMobile ? 'white' : strBackgroundColor"
      :class="{ 'px-4': !bMobile }"
      scroll-threshold="80"
    >
      <v-container v-if="!bMobile" fluid>
        <v-row align="center">
          <v-col cols="auto">
            <v-img
              v-show="bShowLogo"
              ref="startPartRef"
              :src="require('@/assets/logos/logo-kedatech-white.png')"
              contain
              position="left"
              class="my-auto"
              max-height="45"
            />
          </v-col>

          <v-col>
            <div class="d-flex justify-center">
              <div ref="centerPartRef" class="main-r-500 rounded-xl px-4">
                <v-tabs
                  centered
                  optional
                  hide-slider
                  background-color="transparent"
                  height="40"
                  show-arrows
                >
                  <v-tab
                    v-for="(item, i) in m_arrPages"
                    id="tab-items-id"
                    :key="'navbar-item-menu-' + i"
                    :to="item.isMenu ? '#' : { name: item.strPathName }"
                    :ripple="false"
                    :class="`kda-ts-16pt text-capitalize white--text ${item.isMenu && 'px-0'} ${
                      (item.isMenu &&
                        item.menus.map((menu) => menu.strPathName).includes($route.name)) ||
                      item.strPathName === $route.name
                        ? 'wt-black'
                        : 'wt-regular'
                    }`"
                    min-width="96"
                    text
                  >
                    <template v-if="!item.isMenu">
                      {{ item.strText }}
                    </template>

                    <v-menu v-else offset-y open-on-hover>
                      <template #activator="{ on, attrs }">
                        <p
                          v-bind="attrs"
                          class="fill-height d-flex align-center my-auto pl-2"
                          v-on="on"
                        >
                          {{ item.strText }}
                          <v-icon color="white">mdi-menu-down</v-icon>
                        </p>
                      </template>

                      <v-list>
                        <v-list-item
                          v-for="(menu, index) in item.menus"
                          id="sub-menu-item"
                          :key="`${item.strText}-sub-menu-${index}`"
                          :to="{ name: menu.strPathName }"
                          exact-path
                        >
                          <v-list-item-content>
                            <v-list-item-title class="text-center kda-ts-16pt wt-medium">
                              {{ menu.strText }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-tab>

                  <v-tab id="tab-items-id">
                    <v-img
                      :src="require('@/assets/logos/logo-uptech-white.png')"
                      contain
                      position="left"
                      max-height="24"
                      width="50"
                      @click="uptechonclick()"
                    />
                  </v-tab>
                </v-tabs>
              </div>
            </div>
          </v-col>

          <v-col cols="auto">
            <div ref="endPartRef" class="d-flex justify-end">
              <v-btn
                outlined
                elevation="5"
                color="white"
                class="text-capitalize mr-8"
                @click="navToConsultations"
              >
                {{ m_strButton }}
              </v-btn>

              <v-menu offset-y open-on-hover left>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    elevation="3"
                    class="text-capitalize overflow-hidden"
                    height="32"
                    width="32"
                    v-bind="attrs"
                    eager
                    v-on="on"
                  >
                    <v-img :src="langFlagSrc" height="32" width="32" contain eager />
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(lang, index) in m_arrLanguanges"
                    id="changing-lang-item"
                    :key="`${lang.strName}-sub-menu-${index}`"
                    @click="changeLang(lang.strCode)"
                  >
                    <v-list-item-content class="kda-ts-16pt wt-medium">
                      <k-d-a-lang-item
                        :str-title="lang.strName"
                        :lang-src="lang.logoSrc"
                        str-justify="start"
                      />
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <template v-else>
        <v-img
          v-show="bShowLogo"
          :src="require('@/assets/logos/logo-kedatech.png')"
          contain
          max-width="100"
          class="my-auto"
          max-height="45"
        />

        <v-spacer />

        <v-app-bar-nav-icon color="black" @click="m_cmpDrawer = !m_cmpDrawer" />
      </template>
    </v-app-bar>

    <k-d-a-navigation-drawer
      v-model="m_cmpDrawer"
      :arr-pages="m_arrPages"
      :arr-langs="m_arrLanguanges"
      :str-btn="m_strButton"
      @lang="changeLang"
      @close="m_cmpDrawer = !m_cmpDrawer"
      @btn="navToConsultations"
    />
  </div>
</template>

<script>
export default {
  name: 'KDANavigationAppbar',

  components: {
    KDANavigationDrawer: () => import('./KDANavigationDrawer'),
    KDALangItem: () => import('@/components/KDALangItem')
  },

  props: {
    bMobile: {
      type: Boolean,
      default: false
    },
    strBackgroundColor: {
      type: String,
      default: 'appbar'
    },
    strColor: {
      type: String,
      default: 'primary1'
    }
  },

  data: function () {
    return {
      iLastScrollTop: 0,
      bShowLogo: true,
      m_cmpDrawer: null
    };
  },

  computed: {
    m_strButton() {
      return this.$vuetify.lang.t('$vuetify.kda.common.book-banner.btn');
    },
    m_arrPages() {
      return [
        {
          strText: this.$vuetify.lang.t('$vuetify.kda.common.menu.home'),
          strPathName: 'KDAHome'
        },
        {
          strText: this.$vuetify.lang.t('$vuetify.kda.common.menu.about'),
          isMenu: true,
          menus: [
            {
              strText: this.$vuetify.lang.t('$vuetify.kda.common.menu.story'),
              strPathName: 'KDAStory'
            },
            {
              strText: this.$vuetify.lang.t('$vuetify.kda.common.menu.team'),
              strPathName: 'KDATeams'
            },
            {
              strText: this.$vuetify.lang.t('$vuetify.kda.common.menu.tech'),
              strPathName: 'KDATechnology'
            },
            {
              strText: this.$vuetify.lang.t('$vuetify.kda.common.menu.careers'),
              strPathName: 'KDACareers'
            }
          ]
        },
        {
          strText: this.$vuetify.lang.t('$vuetify.kda.common.menu.process'),
          strPathName: 'KDAProcess'
        },
        {
          strText: this.$vuetify.lang.t('$vuetify.kda.common.menu.solutions'),
          strPathName: 'KDASolutions'
        },
        {
          strText: this.$vuetify.lang.t('$vuetify.kda.common.menu.projects'),
          strPathName: 'KDAProject'
        },
        {
          strText: this.$vuetify.lang.t('$vuetify.kda.common.menu.blog'),
          strPathName: 'KDABlog'
        }
      ];
    },
    m_arrLanguanges() {
      return [
        {
          strName: 'Indonesia',
          logoSrc: require('@/assets/icons/flags/id.png'),
          strCode: 'id'
        },
        {
          strName: 'English',
          logoSrc: require('@/assets/icons/flags/en.png'),
          strCode: 'en'
        },
        {
          strName: '简体中文',
          logoSrc: require('@/assets/icons/flags/zhHans.png'),
          strCode: 'zhHans'
        },
        {
          strName: 'Deutsch',
          logoSrc: require('@/assets/icons/flags/de.png'),
          strCode: 'de'
        },
        {
          strName: '日本',
          logoSrc: require('@/assets/icons/flags/ja.png'),
          strCode: 'ja'
        }
      ];
    },
    langFlagSrc() {
      return require(`@/assets/icons/flags/${this.$vuetify.lang.current}.png`);
    }
  },

  mounted() {
    this.updateWidths();
    window.addEventListener('resize', this.updateWidths);
  },

  destroyed() {
    window.removeEventListener('resize', this.updateWidths);
  },

  updated() {
    this.updateWidths();
  },

  methods: {
    uptechonclick(e) {
      window.gtag('event', 'click', {
        'event_label': 'Up Tech'
      });

      window.location.href = 'https://uptech.keda-tech.com';
    },
    navToConsultations() {
      window.gtag('event', 'click', {
        'event_label': 'Book A Chat'
      });

      this.$router.push('/consultations');
    },
    changeLang(code) {
      this.$vuetify.lang.current = code;
    },
    updateWidths() {
      if (
        this.$refs.endPartRef &&
        this.$refs.startPartRef &&
        this.$refs.centerPartRef &&
        this.$refs.containerRef
      ) {
        const endWidth = this.$refs.endPartRef.clientWidth;
        this.$refs.startPartRef.$el.style.width = `${endWidth}px`;

        const containerWidth = this.$refs.containerRef.$el.clientWidth;

        let totalWidth = 0;
        const tabItems = document.querySelectorAll('[id=tab-items-id]');
        tabItems.forEach((el) => (totalWidth += el.clientWidth));
        totalWidth += (tabItems.length - 1) * 32;

        if (endWidth * 2 + totalWidth >= containerWidth) {
          const newWidth = containerWidth - 3 * endWidth;
          this.$refs.centerPartRef.style.width = `${newWidth}px`;
        } else {
          this.$refs.centerPartRef.style.width = 'fit-content';
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import 'src/styles/variables.scss';

#sub-menu-item,
#changing-lang-item {
  cursor: pointer;

  &:hover {
    background-color: $grey100;
  }
}

.v-tabs::v-deep .v-icon {
  color: white !important;
}
</style>
